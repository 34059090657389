import LandCard from '../component/card/landCard';
import NavBar from '../component/navbar/navbar';
import Slogan from '../component/landing/slogan';
import SearchBar from '../component/search/SearchBar';
import ProductTags from '../component/tags/productTags';
import StartSelling from '../component/landing/startSelling';
import DoubleCard from '../component/card/doubleCard';
import PartnerStore from '../component/sections/partnerStore';
import Footer from '../component/footer/footer';
import { useContext, useEffect, useState } from 'react';
import { RegisterContext } from '../component/auth/context/registerContext';
import CGBar, { CGBarSlim } from '../component/card/cbBar';
import { useMediaQuery } from 'react-responsive';
import FilterSystem from '../component/landing/FilterSystem';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCollections, fetchMerchandiseStore } from '../api/auth';
import { toast } from 'react-toastify';
import {
  approveAStoreByCreator,
  getAllPublicMerchandiseAction,
} from '../api/user';
import LoadingState from '../component/loader/LoadingState';
import { calculateProductRating } from '../utils/calculateProductRating';
import { countProductComments } from '../utils/countProductComments';
import axios from 'axios';
import {
  getAllProductCategoriesAction,
  getPublicCatalogues,
} from '../api/admin';
import BuyPageModal from '../component/modals/buypage/BuyPageModal';
import Cookies from 'js-cookie';
import { getTeamRoleKey } from '../utils/getUserTeamRoleKey';

const HomeImg = process.env.PUBLIC_URL + '/assets/image/ltyhomeimage.png';
const HomeImg1 = process.env.PUBLIC_URL + '/assets/image/Rwandanenvironment.png';
const HomeImg2 = process.env.PUBLIC_URL + '/assets/image/homepage.png';
const HomeImg3 = process.env.PUBLIC_URL + '/assets/image/lagosstreet.png';
const HomeImg4 = process.env.PUBLIC_URL + '/assets/image/community.png';


const PictoralImg = process.env.PUBLIC_URL + '/assets/image/runes1.png';
const Pictoral1Img = process.env.PUBLIC_URL + '/assets/image/homepage.png';
const Pictoral2Img = process.env.PUBLIC_URL + '/assets/image/Ngoma.png';
const CatmanImg = process.env.PUBLIC_URL + '/assets/image/collection3.jpg';
const coloredhouseImg =
  process.env.PUBLIC_URL + '/assets/image/collection4.jpg';
const MonkeyFaceImg = process.env.PUBLIC_URL + '/assets/image/collection5.JPG';
const CatPinImg = process.env.PUBLIC_URL + '/assets/image/collection6.JPG';
const WolfDogImg = process.env.PUBLIC_URL + '/assets/image/collection7.JPG';

const ImageGalleryComp = ({ img }) => (
  <img src={img} className='landing__gallery-image' alt='' />
);

let user = {};
if (typeof window !== 'undefined' && Cookies.get('user')) {
  try {
    user = JSON.parse(Cookies.get('user'));
  } catch (error) {
    // Handle the error, e.g., the cookie contains invalid JSON
    console.error("Error parsing 'user' cookie:", error);
  }
}
const Home = () => {
  const [yScroll, setYScroll] = useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [navSearchString, setNavSearchString] = useState('');
  const [collections, setCollections] = useState([]);
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const [allCatalogues, setAllCatalogues] = useState([]);
  const [categories, setCategories] = useState([]);

  const [imgIdx, setImgIdx] = useState(0);
  const backgroundList = [
    {
      src: HomeImg,
      title: 'cg1',
    },
    {
      src: HomeImg1,
      title: 'cg2',
    },
    {
      src: HomeImg2,
      title: 'cg3',
    },
    {
      src: HomeImg3,
      title: 'cg4',
    },
    {
      src: HomeImg4,
      title: 'cg5',
    },
  ];

  setTimeout(() => {
    if (imgIdx === backgroundList.length - 1) return setImgIdx(0);
    setImgIdx(imgIdx + 1);
  }, 20000);

  const handleMainAppClick = () => {
    setShowRegModal((old) => ({ ...old, loginDrop: false }));
  };

  const handleScroll = () => {
    setYScroll(window.scrollY);
  };

  const searchPublicProducts = (queryString) => {
    navigate(`/search/result/${queryString}`);
  };

  const filterPublicProducts = (queryString) => {
    navigate(`/filter/result/${queryString}`);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.addEventListener('scroll', (e) => handleScroll(e));
      if (showRegModal.login)
        setShowRegModal({ ...showRegModal, login: false });
    }

    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e));
      isMounted = false;
    };
  }, [setShowRegModal, showRegModal]);

  useEffect(() => {
    let teamKey = getTeamRoleKey(user.team_roles, 'store');
    if (user && teamKey) {
      dispatch(fetchMerchandiseStore({ page: 1 }))
        .unwrap()
        .then((response) => {
          if (response.store_approved === 0) {
            dispatch(
              approveAStoreByCreator({
                approveData: { id: teamKey },
              })
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    }
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllPublicMerchandiseAction({ page: 1 }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          const filteredProducts = response.data.filter(
            (product) => product.management_approved !== false
          );
          setProducts(filteredProducts);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    dispatch(getAllProductCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategories(response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
      setIsLoading(false);
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getPublicCatalogues())
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setAllCatalogues(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    const base_url = process.env.REACT_APP_BASE_URL || '';
    const url = window.location.href.replace('#', '?');
    const searchParams = new URLSearchParams(new URL(url).search);
    let access_token = searchParams.get('access_token');
    let id_token = searchParams.get('id_token');

    // Check if google login or apple login
    let socialite_url;
    let token;

    if (access_token) {
      socialite_url = `${base_url}/api/auth/socialite/google/callback`;
      token = access_token;
    }

    if (id_token) {
      socialite_url = `${base_url}/api/auth/socialite/apple/callback`;
      token = id_token;
    }

    if (socialite_url && token) {
      axios({
        url: socialite_url,
        method: 'get',
        params: {
          token: token,
        },
      })
        .then((res) => {
          return console.log({ res });
        })
        .catch((err) => {
          return console.log({ err });
        });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    if (typeof window !== 'undefined' && Cookies.get('user')) {
      dispatch(fetchCollections({}))
        .unwrap()
        .then((response) => {
          setCollections(response.wish_list_merchandise);
          if (isMounted) setIsLoading(false);
        })
        .catch((err) => {
          isMounted && setIsLoading(false);
          if (err.response) {
            toast.error(err.response.data.message);
          }
        });
    }
    return () => {
      isMounted = false;
      setIsLoading(false);
    };
  }, [dispatch]);
  const [productId, setProductId] = useState(null);
  const [buyPagefadeOut, setBuyPageFadeOut] = useState(false);
  const [buyPageModal, setBuyPageModal] = useState(null);
  const [activeFilter, setActiveFilter] = useState('recent');

  const filterProducts = () => {
    let sortedProducts = products;

    if (activeFilter === 'recent') {
      // sortedProducts = products.sort((a, b) => {
      //   const dateA = new Date(a.created_at);
      //   const dateB = new Date(b.created_at);
      //   return dateB - dateA;
      // });
    }

    if (activeFilter === 'popular') {
      sortedProducts = products.sort((a, b) => b.rating_count - a.rating_count);
    }

    if (activeFilter === 'sale') {
      sortedProducts = products.filter((obj) => obj.merchandise_amount < 5);
    }

    if (activeFilter === 'free') {
      sortedProducts = products.filter((obj) => obj.merchandise_amount === 0);
    }

    if (activeFilter === 'basicPrice') {
      sortedProducts = products.filter((obj) => obj.merchandise_amount > 0);
    }

    if (activeFilter === 'commercialPrice') {
      sortedProducts = sortedProducts.filter(
        (obj) => obj.merchandise_commercial_amount > 0
      );
    }

    return sortedProducts;
  };

  return (
    <section onClick={handleMainAppClick} className='landing__main-app'>
      {isLoading ? (
        <div
          style={{
            width: '80%',
            margin: '2rem auto',
            justifyContent: 'center',
          }}
        >
          <LoadingState />
        </div>
      ) : (
        <div className='landing__app-container'>
          <header
            className='landingpage__header'
            style={{ backgroundImage: `url(${backgroundList[imgIdx].src})` }}
          >
            <NavBar
              pageType={'home'}
              shortSearch={yScroll > 470}
              style={{ paddingBottom: '0' }}
              search={yScroll > 470}
              scrollToSearch={setYScroll}
              categoriesArr={categories}
              categorizeProducts={filterPublicProducts}
              isHome={true}
              setActiveSort={setActiveFilter}
              searchString={navSearchString}
              setSearchString={setNavSearchString}
              onSearch={() => searchPublicProducts(navSearchString)}
            />

            <Slogan />
          </header>

          <main className='landing__main'>
            <section className='landing__search-section'>
              <SearchBar
                searchString={searchString}
                setSearchString={setSearchString}
                onSearch={() => searchPublicProducts(searchString)}
              />
            </section>

            {allCatalogues.length !== 0 &&
              allCatalogues.map((cat) => (
                <div key={cat.id}>
                  <section
                    id='catalogues'
                    className='landing__weekly-freebies-container'
                  >
                    <button className='landing__weekly-freebies-btn'>
                      <span
                        style={{
                          transform: 'skewX(25deg)',
                          textTransform: 'uppercase',
                        }}
                      >
                        {cat.catalogue_title}
                      </span>
                    </button>
                  </section>
                  <section className='landing__freebies-container'>
                    <div className='landing__freebies-inner-container'>
                      {cat.merchandise?.slice(0, 5).map((prod, index) => {
                        return (
                          <LandCard
                            id={prod.id}
                            key={prod.merchandise_name + index}
                            onClick={() => {
                              setBuyPageModal(!buyPageModal);
                              setProductId(prod.id);
                            }}
                            img={
                              prod.merchandise_banners?.length > 0
                                ? prod.merchandise_banners[0]
                                : null
                            }
                            title={prod.merchandise_name}
                            ratings={calculateProductRating(prod.ratings)}
                            owner={prod.store?.store_name}
                            numOfComments={countProductComments(prod.ratings)}
                            numReviewers={prod.ratings?.length}
                            price={prod.merchandise_amount}
                            firstTag={
                              prod.merchandise_tags
                                ? prod.merchandise_tags[0]
                                : ''
                            }
                            // firstCate={
                            //   prod.merchandise_categories
                            //     ? prod.merchandise_categories[0]
                            //     : ''
                            // }
                            navigateToStore={() => {
                              navigate(`/store/${prod.store_id}`);
                              console.log('click');
                            }}
                            productId={prod.id}
                            wishlistIds={collections}
                            setCollections={setCollections}
                          />
                        );
                      })}
                    </div>
                  </section>
                </div>
              ))}
            {!!categories.length !== 0 && !isMobile && (
              <section className='landing__cg-tutorials'>
                {yScroll > 1200 ? (
                  <CGBarSlim
                    categoryList={categories}
                    onFilter={filterPublicProducts}
                  />
                ) : null}

                <CGBar
                  categoryList={categories}
                  onFilter={filterPublicProducts}
                  style={{
                    visibility: yScroll < 1200 ? 'visible' : 'visible',
                  }}
                />
              </section>
            )}

            {/* {dailyPicksProduct.length !== 0 && (
              <>
                {' '}
                <section
                  id='picks'
                  className='landing__weekly-freebies-container'
                >
                  <button className='landing__weekly-freebies-btn'>
                    <span style={{ transform: 'skewX(25deg)' }}>
                      DAILY PICKS
                    </span>
                  </button>
                </section>
               
              </>
            )} */}
            <section
              id='products'
              style={{ height: '8vh' }}
              className='landing__weekly-freebies-container'
            >
              <button className='landing__weekly-freebies-btn'>
                <span style={{ transform: 'skewX(25deg)' }}>
                  ALL&nbsp;PRODUCTS
                </span>
              </button>

              {!isMobile && (
                <FilterSystem
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                />
              )}
            </section>

            <section className='landing__all-products-container'>
              <div className='landing__all-products-inner-container'>
                {!products.length && (
                  <div className='user__no-product-div'>
                    You do not have any products yet!
                  </div>
                )}
                {products &&
                  filterProducts().map((prod, index) => {
                    return (
                      <LandCard
                        id={prod.id}
                        key={prod.merchandise_name + index}
                        onClick={() => {
                          setBuyPageModal(!buyPageModal);
                          setProductId(prod.id);
                        }}
                        img={
                          prod.merchandise_banners?.length > 0
                            ? prod.merchandise_banners[0]
                            : null
                        }
                        title={prod.merchandise_name}
                        ratings={calculateProductRating(prod.ratings)}
                        owner={prod.store?.store_name}
                        numOfComments={countProductComments(prod.ratings)}
                        numReviewers={prod.ratings?.length}
                        price={prod.merchandise_amount}
                        firstTag={
                          prod.merchandise_tags ? prod.merchandise_tags[0] : ''
                        }
                        firstCate={
                          prod.merchandise_categories
                            ? prod.merchandise_categories[0]
                            : ''
                        }
                        navigateToStore={() => {
                          navigate(`/store/${prod.store_id}`);
                        }}
                        productId={prod.id}
                        wishlistIds={collections}
                        setCollections={setCollections}
                      />
                    );
                  })}
              </div>
            </section>

            {buyPageModal && (
              <BuyPageModal
                toggleShowModal={() => setBuyPageModal(!buyPageModal)}
                fadeOut={buyPagefadeOut}
                setFadeOut={setBuyPageFadeOut}
                id={productId}
              />
            )}

            {products.length && (
              <div className='landing__filter-tag-container'>
                <div
                  className='landing__filter-tag-inner-container'
                  onClick={() => navigate('/search/result/all')}
                >
                  <ProductTags txt={'BROWSE ALL PRODUCTS'} />
                </div>
              </div>
            )}

            <section className='landing__start-selling'>
              <StartSelling onClick={(e) => navigate('/open/store')} />
            </section>

            <section
              id='collections'
              className='landing__weekly-freebies-container'
            >
              <button className='landing__weekly-freebies-btn'>
                <span style={{ transform: 'skewX(25deg)' }}>
                  RECENT UPLOADS
                </span>
              </button>
            </section>

            <section className='landing__latest-collection-cont'>
              <div className='landing__double-inner-container'>
                {products
                  ? products.slice(0, 2).map((prod, index) => {
                      return (
                        <DoubleCard
                          key={prod.id + index}
                          title={prod.merchandise_name}
                          img={
                            prod.merchandise_banners?.length > 0
                              ? prod.merchandise_banners[0]
                              : null
                          }
                          owner={prod.store?.store_name}
                          firstTag={
                            prod.merchandise_tags
                              ? prod.merchandise_tags[0]
                              : ''
                          }
                        />
                      );
                    })
                  : null}
              </div>
            </section>

            <section className='landing__browse-all-products-container'>
              <button
                className='landing__browse-all-products-btn'
                onClick={() => navigate('/search/result/all')}
              >
                <span className='landing__browse-all-products-btn-text'>
                  BROWSE&nbsp;ALL&nbsp;PRODUCTS
                </span>
              </button>
            </section>

            <section
              id='earn'
              style={{ marginTop: '2rem' }}
              className='landing__weekly-freebies-container'
            >
              <button className='landing__weekly-freebies-btn'>
                <span style={{ transform: 'skewX(25deg)' }}>
                  EARN MORE MONEY WITH LOOOTY
                </span>
              </button>
            </section>

            <PartnerStore />

            <section id='awesome' className='landing__awesome-arts-sec'>
              <div className='landing__awesome-arts-sec-inner'>
                <button className='landing__awesome-arts-btn'>
                  <span className='landing__awesome-arts-btn-text'>
                    AWESOME ARTS BY OUR COMMUNITY
                  </span>
                </button>
              </div>
            </section>

            <section className='landing__gallery'>
              <div className='landing__gallery-first-move-container'>
                <ImageGalleryComp img={CatmanImg} />
                <ImageGalleryComp img={coloredhouseImg} />
                <ImageGalleryComp img={CatPinImg} />
                <ImageGalleryComp img={MonkeyFaceImg} />
                <ImageGalleryComp img={Pictoral1Img} />
                <ImageGalleryComp img={WolfDogImg} />
                <ImageGalleryComp img={PictoralImg} />
              </div>

              <div className='landing__gallery-second-move-container'>
                <ImageGalleryComp img={CatmanImg} />
                <ImageGalleryComp img={coloredhouseImg} />
                <ImageGalleryComp img={MonkeyFaceImg} />
                <ImageGalleryComp img={coloredhouseImg} />
                <ImageGalleryComp img={WolfDogImg} />
                <ImageGalleryComp img={PictoralImg} />
                <ImageGalleryComp img={CatPinImg} />
                <ImageGalleryComp img={Pictoral2Img} />
                <ImageGalleryComp img={CatmanImg} />

                <ImageGalleryComp img={PictoralImg} />
              </div>
            </section>
          </main>

          <footer>
            <Footer categories={allCatalogues} />
          </footer>
        </div>
      )}
    </section>
  );
};

export default Home;
