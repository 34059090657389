import { useEffect, useState } from 'react';
import AdminFreebiesSidebar from '../AdminFreebiesSidebar';
import { setSelectedSubPage } from '../../../redux/reducers/userStateReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  approveCataloguesByAdmin,
  getAllMerchandiseAction,
  getAllProductCategoriesAction,
  getCataloguesByAdmin,
  searchAllMerchandiseAction,
  searchCatalogues,
  unapproveCataloguesByAdmin,
} from '../../../api/admin';
import { toast } from 'react-toastify';
import AdminProductCard from '../card/AdminProductCard';
import LoadingState from '../../loader/LoadingState';
import FilterSelector from '../FilterSelector';
import FilterClear from '../FilterClear';
import { calculateProductRating } from '../../../utils/calculateProductRating';
import { fetchCollections } from '../../../api/auth';
import { AiFillPlusCircle } from 'react-icons/ai';
import CreateCatalogueModal from '../modals/catalogues/CreateCatalogue';
import LoootyLoader from '../../loader/loootyLoader';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import FilterSearchBar from '../FilterSearchBar';
import MainPagination from '../../pagination/MainPagination';
// import { countProductComments } from '../../../utils/countProductComments';

const coloredhouseImg = '/assets/image/coloredhouse.webp';

const ProductAdmin = () => {
  const [activeSideTab, setActiveSideTab] = useState('All products');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productsList, setProductsList] = useState([]);
  const [sortedProductsList, setSortedProductsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [collections, setCollections] = useState([]);
  const [allCatalogues, setAllCatalogues] = useState([]);
  const [activeCatId, setActiveCatId] = useState(null);
  const [showCatalogueModal, setShowCatalogueModal] = useState(null);
  const [createModalfadeOut, setCreateModalFadeOut] = useState(false);
  const [catLoading, setCatLoading] = useState(false);
  const [unapproveLength, setUnnaproved] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  // pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [resultSize, setResultSize] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllProductCategoriesAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setCategoriesList(response);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const searchProducts = async () => {
    setIsLoading(true);
    await dispatch(
      searchAllMerchandiseAction({
        query: {
          search: searchString,
        },
      })
    )
      .unwrap()
      .then((response) => {
        setSortedProductsList(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response) {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });
  };
  const [activeTabView, setActiveTabview] = useState('approved');

  function filterProducts(property, filterBy) {
    let filterProducts = [];
    let merchandiseList = productsList;

    // reset pagination state
    setCurrentPage(1);
    setResultSize(0);
    setTotalResults(0);
    setTotalPages(0);

    switch (property.toLowerCase()) {
      case 'category':
        if (activeTabView === 'approved') {
          filterProducts = merchandiseList?.filter((product) => {
            let found =
              product?.merchandise_categories?.find(
                (category) => category.toLowerCase() === filterBy.toLowerCase()
              ) && product.management_approved === true;
            if (found) return product;
            return null;
          });
        } else if (activeTabView === 'review') {
          filterProducts = merchandiseList?.filter((product) => {
            let found =
              product?.merchandise_categories?.find(
                (category) => category.toLowerCase() === filterBy.toLowerCase()
              ) && product.management_approved === false;
            if (found) return product;
            return null;
          });
        }
        break;

      case 'license':
        if (activeTabView === 'approved') {
          if (filterBy.toLowerCase() === 'commercial license') {
            filterProducts = merchandiseList?.filter(
              (product) =>
                product.merchandise_commercial_amount !== null &&
                product.merchandise_commercial_amount !== 0 &&
                product.management_approved === true
            );
          }

          if (filterBy.toLowerCase() === 'basic license') {
            filterProducts = merchandiseList?.filter(
              (product) =>
                product.merchandise_amount !== null &&
                product.management_approved === true
            );
          }
        } else if (activeTabView === 'review') {
          if (filterBy.toLowerCase() === 'commercial license') {
            filterProducts = merchandiseList?.filter(
              (product) =>
                product.merchandise_commercial_amount !== null &&
                product.merchandise_commercial_amount !== 0 &&
                product.management_approved === false
            );
          }

          if (filterBy.toLowerCase() === 'basic license') {
            filterProducts = merchandiseList?.filter(
              (product) =>
                product.merchandise_amount !== null &&
                product.management_approved === false
            );
          }
        }

        break;

      case 'price':
        let filterByPrice = filterBy.split('to');
        let startPrice = Number(filterByPrice[0]);
        let endPrice = Number(filterByPrice[1]);
        if (activeTabView === 'approved') {
          if (filterBy === 'All') {
            filterProducts = merchandiseList?.filter(
              (product) => product.management_approved === true
            );
          } else if (filterBy === 'Over 200') {
            filterProducts = merchandiseList?.filter((product) => {
              if (
                (product.merchandise_amount >= 200 &&
                  product.management_approved === true) ||
                (product.merchandise_commercial_amount >= 200 &&
                  product.management_approved === true)
              ) {
                return product;
              }
              return null;
            });
          } else {
            filterProducts = merchandiseList?.filter((product) => {
              if (
                (product.merchandise_amount >= startPrice &&
                  product.merchandise_amount <= endPrice &&
                  product.management_approved === true) ||
                (product.merchandise_commercial_amount >= startPrice &&
                  product.merchandise_commercial_amount <= endPrice &&
                  product.management_approved === true)
              ) {
                return product;
              }
              return null;
            });
          }
        } else if (activeTabView === 'review') {
          if (filterBy === 'All') {
            filterProducts = merchandiseList?.filter(
              (product) => product.management_approved === false
            );
          } else if (filterBy === 'Over 200') {
            filterProducts = merchandiseList?.filter((product) => {
              if (
                (product.merchandise_amount >= 200 &&
                  product.management_approved === false) ||
                (product.merchandise_commercial_amount >= 200 &&
                  product.management_approved === false)
              ) {
                return product;
              }
              return null;
            });
          } else {
            filterProducts = merchandiseList?.filter((product) => {
              if (
                (product.merchandise_amount >= startPrice &&
                  product.merchandise_amount <= endPrice &&
                  product.management_approved === false) ||
                (product.merchandise_commercial_amount >= startPrice &&
                  product.merchandise_commercial_amount <= endPrice &&
                  product.management_approved === false)
              ) {
                return product;
              }
              return null;
            });
          }
        }

        break;

      default:
        if (activeTabView === 'approved') {
          filterProducts = merchandiseList?.filter(
            (product) => product.management_approved === true
          );
        } else if (activeTabView === 'review') {
          filterProducts = merchandiseList?.filter(
            (product) => product.management_approved === false
          );
        }
    }
    setSortedProductsList(filterProducts);

    // set pagination state
    setResultSize(filterProducts?.length);
    setTotalResults(filterProducts?.length);
    setTotalPages(1);
  }

  const filteredDisplay =
    activeTabView === 'approved'
      ? productsList?.filter((product) => product.management_approved === true)
      : productsList?.filter(
        (product) => product.management_approved === false
      );
  //fetch catalogues
  const fetchCatalogues = async () => {
    let isMounted = true;
    setIsLoading(true);

    await dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (!response) {
          setAllCatalogues([]);
          setIsLoading(false);
        }

        if (isMounted) {
          setAllCatalogues(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
      setIsLoading(false);
    };
  };

  //approve catalogue
  const approve = async (id) => {
    let isMounted = true;
    setIsLoading(true);

    await dispatch(approveCataloguesByAdmin({ id: { id } }))
      .unwrap()
      .then(() => {
        fetchCatalogues();
        if (isMounted) {
          toast.success('Catalogue was approved', {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };

  //unapprove catalogues
  const unaprove = async (id) => {
    let isMounted = true;

    setIsLoading(true);

    await dispatch(unapproveCataloguesByAdmin({ id: { id } }))
      .unwrap()
      .then(() => {
        fetchCatalogues();
        if (isMounted) {
          toast.success('Catalogue was unapproved', {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };

  // fetch all products
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllMerchandiseAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setProductsList(response?.data);
          setSortedProductsList(response?.data);
          const unapprovedProductLength = response?.data.filter(
            (product) => product.management_approved === false
          );
          setUnnaproved(unapprovedProductLength.length);

          // set pagination state
          setResultSize(response?.to);
          setTotalResults(response?.total);
          setTotalPages(response?.last_page);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  // handle pagination
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    dispatch(getAllMerchandiseAction({ page: currentPage }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setSortedProductsList(response?.data);
          const unapprovedProductLength = response?.data.filter(
            (product) => product.management_approved === false
          );
          
          setUnnaproved(unapprovedProductLength.length);
          // set pagination state
          setResultSize(response?.to);
          setTotalResults(response?.total);
          setTotalPages(response?.last_page);

          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
        setIsLoading(false);
      });
    return () => {
      isMounted = false;
      return;
    };
  }, [currentPage, dispatch]);

  const refetchProducts = () => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getAllMerchandiseAction({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setProductsList(response?.data);
          setSortedProductsList(response?.data);
          const unapprovedProductLength = response?.data.filter(
            (product) => product.management_approved === false
          );
          setUnnaproved(unapprovedProductLength.length);
          if (unapprovedProductLength.length === 0) {
            setActiveTabview('approved');
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(getCataloguesByAdmin({}))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setAllCatalogues(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      setIsLoading(false);

      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchCollections({}))
      .unwrap()
      .then((response) => {
        setCollections(response.wish_list_merchandise);
        if (isMounted) setIsLoading(false);
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  // filter products by category
  useEffect(() => {
    if (activeCatId === null) {
      setSortedProductsList(productsList);
      return;
    }
    let isMounted = true;
    setCatLoading(true);
    setSortedProductsList([]);

    // reset pagination
    setCurrentPage(1);
    setResultSize(0);
    setTotalResults(0);
    setTotalPages(0);

    dispatch(searchCatalogues({ id: activeCatId }))
      .unwrap()
      .then((response) => {
        if (isMounted) {
          setSortedProductsList(response.merchandise);
          setCatLoading(false);

          // set pagination state
          setResultSize(response?.length);
          setTotalResults(response?.length);
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: 'toast__message',
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [activeCatId, dispatch, productsList]);

  // filter products by tab
  useEffect(() => {
    // reset pagination state
    setCurrentPage(1);
    setResultSize(0);
    setTotalResults(0);
    setTotalPages(0);

    if (productsList && productsList.length > 0) {
      if (activeTabView === 'approved') {
        const approvedProducts = productsList?.filter(
          (product) => product.management_approved === true
        );
        setSortedProductsList(approvedProducts);
        setSelectedProduct(approvedProducts[0]);

        // set pagination state
        setResultSize(approvedProducts?.length);
        setTotalResults(approvedProducts?.length);
        setTotalPages(1);
      } else if (activeTabView === 'review') {
        const reviewProducts = productsList?.filter(
          (product) => product.management_approved === false
        );
        setSortedProductsList(reviewProducts);
        setSelectedProduct(reviewProducts[0]);

        // set pagination state
        setResultSize(reviewProducts?.length);
        setTotalResults(reviewProducts?.length);
        setTotalPages(1);
      }
    }
  }, [activeTabView, productsList]);
  return (
    <section className='admin__freebies-container'>
      {isLoading ? (
        <div
          style={{
            paddingTop: '5rem',
            height: '5rem',
            width: '100%',
            backgroundColor: '#191921',
          }}
        />
      ) : (
        <AdminFreebiesSidebar
          setActiveSideTab={setActiveSideTab}
          activeSideTab={activeSideTab}
          data={selectedProduct}
          allCatalogues={allCatalogues}
          refetchCatalogues={fetchCatalogues}
          refetchProducts={refetchProducts}
          unapproveLength={unapproveLength}
          sideActionOnclick={() => {
            navigate(`/admin/expanded/${selectedProduct?.id}`);
            dispatch(setSelectedSubPage({ selected: 'store' }));
          }}
          activeTabView={activeTabView}
          setActiveTabview={setActiveTabview}
          sidebarMaininfoIts={[
            {
              leftInfo: 'Rating',
              rightInfo: selectedProduct
                ? `${calculateProductRating(selectedProduct?.ratings)} stars`
                : null,
            },
            // {
            //   leftInfo: "views this month",
            //   rightInfo: "814",
            // },

            // {
            //   leftInfo: "Total Views",
            //   rightInfo: "714,436",
            // },

            {
              leftInfo: 'Wish-list',
              rightInfo: selectedProduct?.wishlist_count,
            },

            {
              leftInfo: 'Purchases',
              rightInfo: selectedProduct?.sold,
            },
          ]}
        />
      )}

      <div className='admin__freebies-inner-container'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* <TagsComp
            showFilter={false}
            search={searchString}
            setSearch={(e) => setSearchString(e.target.value)}
            searchPlaceHolder={'Search products'}
            onSearch={searchProducts}
          /> */}
          <div className='admin__feature-tags-container'>
            <div style={{ width: '80%', marginLeft: '1rem' }}>
              <FilterSearchBar
                search={searchString}
                setSearchString={setSearchString}
                onSearch={searchProducts}
                placeHolder={'Search Products'}
              />
            </div>
          </div>

          <section
            className='admin__filter-selector-parent-container'
            style={{ marginLeft: '0.4rem', backgroundColor: '#191921' }}
          >
            <FilterSelector
              title='CATEGORY'
              options={categoriesList?.map((cat) => cat.name)}
              onChange={filterProducts}
            />

            <FilterSelector
              title='LICENSE'
              options={['Basic license', 'Commercial license']}
              onChange={filterProducts}
            />

            <FilterSelector
              title='PRICE'
              options={[
                'All',
                '0 to 50',
                '51 to 100',
                '101 to 200',
                'Over 200',
              ]}
              onChange={filterProducts}
            />

            <FilterClear
              onClick={() => setSortedProductsList(filteredDisplay)}
            />
          </section>
        </div>

        {isLoading ? (
          <div
            style={{
              width: '80%',
              margin: '2rem auto',
              justifyContent: 'center',
            }}
          >
            <LoadingState />
          </div>
        ) : (
          <>
            <div
              className='admin__catalogues'
              style={{
                width: '100%',
                paddingBottom: '0.3rem',
                overflowX: 'scroll',
                borderBottom: 'none',
              }}
            >
              <div
                className={
                  activeTabView === 'review'
                    ? 'no-display'
                    : 'admin__catalogues-inner'
                }
              >
                <p
                  className={activeCatId === null ? 'active' : ''}
                  onClick={() => setActiveCatId(null)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  All
                </p>
                {allCatalogues?.map((catalogue, index) => (
                  <p
                    className={activeCatId === catalogue.id ? 'active' : ''}
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      minWidth: '100px',
                      gap: '1rem',
                    }}
                    onClick={() => setActiveCatId(() => catalogue.id)}
                  >
                    {catalogue.catalogue_title}

                    {catalogue.visibility === 'private' ? (
                      <span onClick={() => approve(catalogue.id)}>
                        <FaPlusCircle color='green' />
                      </span>
                    ) : (
                      <span onClick={() => unaprove(catalogue.id)}>
                        <FaTimesCircle color='#c51414' />
                      </span>
                    )}
                  </p>
                ))}
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    paddingBottom: '0.2rem',
                  }}
                  onClick={() => setShowCatalogueModal(true)}
                >
                  <AiFillPlusCircle size={20} color='#7c187a' />
                  <span>Add Catalogue</span>
                </p>
              </div>
            </div>
            {showCatalogueModal && (
              <CreateCatalogueModal
                toggleShowModal={() =>
                  setShowCatalogueModal(!showCatalogueModal)
                }
                isLoading={isLoading}
                fadeOut={createModalfadeOut}
                setFadeOut={setCreateModalFadeOut}
                refetch={fetchCatalogues}
                products={productsList}
              />
            )}
            <div className='admin__freebies-inner-container-inner'>
              {!sortedProductsList && (
                <div className='user__no-product-div'>
                  You do not have any products yet!
                </div>
              )}
              {catLoading && (
                <div>
                  <LoootyLoader />
                </div>
              )}
              {sortedProductsList?.map((prod) => {
                return (
                  <>
                    <AdminProductCard
                      key={prod.id}
                      onClick={() => handleProductClick(prod)}
                      selectedProduct={selectedProduct}
                      img={
                        prod.merchandise_banners?.length > 0
                          ? prod.merchandise_banners[0]
                          : coloredhouseImg
                      }
                      title={prod.merchandise_name}
                      owner={prod.store?.store_name}
                      price={prod.merchandise_amount}
                      firstTag={
                        prod.merchandise_tags ? prod.merchandise_tags[0] : ''
                      }
                      ratingsCount={prod.rating_count}
                      ratingsScore={prod.rating_score}
                      navigateToStore={() =>
                        navigate(`/store/${prod.store_id}`)
                      }
                      favCount={prod.wishlist_count}
                      productId={prod.id}
                      wishlistIds={collections}
                      setCollections={setCollections}
                    />
                  </>
                );
              })}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '1rem',
                  textAlign: 'center',
                  gridColumn: '1/-1',
                }}>
                {(totalPages > 1) && <MainPagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  totalResults={totalResults}
                  resultSize={resultSize}
                />}
                <div
                  style={{
                    color: 'white',
                    fontWeight: 600,
                    padding: '2rem',
                    fontSize: '1.2rem',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <p>
                    Showing {resultSize} of{' '}
                    {totalResults} Products.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ProductAdmin;
