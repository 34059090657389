import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getTeamRoleKey } from '../utils/getUserTeamRoleKey';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getAllUsersAction = createAsyncThunk(
  'user/index',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/user/index?properties=1`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const blockUserAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/user/block`,
        params.blockData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unblockUserAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/user/unblock`,
        params.unblockData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const showAUserAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/user/show?user_id=${params.user_id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAStoreMerchandise = createAsyncThunk(
  'api/merchandise',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/user/index?properties=1`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// export const blockUserAction = createAsyncThunk(
//   'api/user',
//   async (params, thunkAPI) => {
//     try {
//       let state = thunkAPI.getState();
//       let teamKey = getTeamRoleKey(['store', 'employee', 'admin'], 'admin');
//       const newConfig = {
//         ...config,
//         'Content-Type': 'application/json',
//         headers: {
//           ...config.headers,
//           Team: teamKey,
//           Authorization: `Bearer ${state.user.user.access_token}`,
//         },
//       };
//       const data = await axios.post(
//         `${baseUrl}/api/user/block`,
//         params.blockData,
//         newConfig
//       );

//       return data.data.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

// export const unblockUserAction = createAsyncThunk(
//   'api/user',
//   async (params, thunkAPI) => {
//     try {
//       let state = thunkAPI.getState();
//       let teamKey = getTeamRoleKey(['store', 'employee', 'admin'], 'admin');
//       const newConfig = {
//         ...config,
//         'Content-Type': 'application/json',
//         headers: {
//           ...config.headers,
//           Team: teamKey,
//           Authorization: `Bearer ${state.user.user.access_token}`,
//         },
//       };
//       const data = await axios.post(
//         `${baseUrl}/api/user/unblock`,
//         params.unblockData,
//         newConfig
//       );

//       return data.data.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

// export const showAUserAction = createAsyncThunk(
//   'api/user',
//   async (params, thunkAPI) => {
//     try {
//       let state = thunkAPI.getState();
//       let teamKey = getTeamRoleKey(['store', 'employee', 'admin'], 'admin');
//       const newConfig = {
//         ...config,
//         'Content-Type': 'application/json',
//         headers: {
//           ...config.headers,
//           Team: teamKey,
//           Authorization: `Bearer ${state.user.user.access_token}`,
//         },
//       };
//       const data = await axios.get(
//         `${baseUrl}/api/user/show?user_id=${params.user_id}&properties=1`,
//         newConfig
//       );

//       return data.data.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

// export const fetchAStoreMerchandise = createAsyncThunk(
//   'api/merchandise',
//   async (params, thunkAPI) => {
//     try {
//       let state = thunkAPI.getState();
//       let teamKey = getTeamRoleKey(['store', 'employee', 'admin'], 'admin');
//       const newConfig = {
//         ...config,
//         'Content-Type': 'application/json',
//         headers: {
//           ...config.headers,
//           Team: teamKey,
//           Authorization: `Bearer ${state.user.user.access_token}`,
//         },
//       };
//       const data = await axios.post(
//         `${baseUrl}/api/merchandise/filter/index`,
//         params.filterData,
//         newConfig
//       );

//       return data.data.data;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

export const approveAMerchandiseByAdmin = createAsyncThunk(
  'api/merchandise/approve',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/approve/management`,
        params.approveData,
        newConfig
      );
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unapproveAMerchandiseByAdmin = createAsyncThunk(
  'api/merchandise/approve',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/merchandise/delete`,
        params.approveData,
        newConfig
      );
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchALibrary = createAsyncThunk(
  'api/library',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/library/show?user_id=${params.id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAUserFolders = createAsyncThunk(
  'api/folder',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/folder/filter/index`,
        params.filterData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchStoreSalesStatsAsAdminAction = createAsyncThunk(
  'api/stats',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/stats/sales`,
        params.storeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchStoreStatsAsAdminAction = createAsyncThunk(
  'api/stats',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/stats/payout/store`,
        params.storeData,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUserWalletAction = createAsyncThunk(
  'api/wallet',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/wallet/show?user_id=${params.user_id}`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchUserNotificationSettings = createAsyncThunk(
  'api/settings/notification',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/settings/notification/show?user_id=${params.user_id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateNotificationSettingsByAdminAction = createAsyncThunk(
  'user/settings/notification',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/settings/notification/update`,
        params.updateData,
        newConfig
      );

      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAStoreSettingsByAdminAction = createAsyncThunk(
  'api/settings/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
          Team: teamKey,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/settings/store/show?user_id=${params.user_id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateAStoreSettingsByAdminAction = createAsyncThunk(
  'api/settings/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/settings/store/update`,
        params.updateData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const showUserByAdminAction = createAsyncThunk(
  'user/show',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/user/show?user_id=${params.user_id}&properties=1`,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateUserByAdminAction = createAsyncThunk(
  'user/update',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/user/update`,
        params.updateData,
        newConfig
      );
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllStoresAction = createAsyncThunk(
  'store/index',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/store/index?properties=1`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveAStoreByManagementAction = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      console.log(teamKey);

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/approve/management`,
        params.approveData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unapproveAStoreByManagementAction = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/unapprove/management`,
        params.unapproveData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchUsersAction = createAsyncThunk(
  'api/user',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/user/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchStoresAction = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllPayoutRequestsAction = createAsyncThunk(
  'payout-request/index',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/payout-request/index?properties=1`,
        newConfig
      );

      return data.data.data?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveAPayoutRequestAction = createAsyncThunk(
  'api/payout-request',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/payout-request/approve`,
        params.approveData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const postponeAPayoutRequestAction = createAsyncThunk(
  'api/payout-request',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/payout-request/postpone`,
        params.postponeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const rejectAPayoutRequestAction = createAsyncThunk(
  'api/payout-request',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/payout-request/reject`,
        params.rejectData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchPayoutRequestsAction = createAsyncThunk(
  'api/payout-request',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/payout-request/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllProductCategoriesAction = createAsyncThunk(
  'product/category/index',
  async (_, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/product/category/index`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllProductTagsAction = createAsyncThunk(
  'product/tag/index',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          // Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/product/tag/index?properties=1`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createProductCategoryAction = createAsyncThunk(
  'api/product/category',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/product/category/store`,
        params.createData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteProductCategoryAction = createAsyncThunk(
  'api/product/category',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/product/category/delete`,
        params.deleteData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createProductTagAction = createAsyncThunk(
  'api/product/tag',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/product/tag/store`,
        params.createData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteProductTagAction = createAsyncThunk(
  'api/product/tag',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/product/tag/delete`,
        params.deleteData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllMerchandiseAction = createAsyncThunk(
  'merchandise/index',
  async ({ page = 1 }, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/merchandise/index?properties=1&page=${page}`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchAllMerchandiseAction = createAsyncThunk(
  'merchandise/search/index',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/merchandise/search/index`,
        params.query,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAStoreByAdminAction = createAsyncThunk(
  'api/store',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      if (!teamKey) return;
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/store/show?id=${params.storeId}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSystemStatisticsByAdminAction = createAsyncThunk(
  'api/admin/stats',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/stats/system`,
        {},
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchCustodialAccountByAdminAction = createAsyncThunk(
  'api/admin/custodial',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/custodial-account/index?properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateApplicationSettingsByAdminAction = createAsyncThunk(
  'api/admin/settings',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/application-setting/update`, // Updated endpoint
        params, // Send the parameters directly
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAMerchandiseByAdminAction = createAsyncThunk(
  '/merchandise/show',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/merchandise/show?id=${params.id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCataloguesByAdmin = createAsyncThunk(
  'catalogue/show',
  async (thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/catalogue/index?properties=1&approved=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveCataloguesByAdmin = createAsyncThunk(
  'catalogue/approve',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/catalogue/approve/management`,
        params.id,
        newConfig
      );

      thunkAPI.dispatch(getCataloguesByAdmin());
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unapproveCataloguesByAdmin = createAsyncThunk(
  'catalogue/unapprove',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');

      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/catalogue/unapprove/management`,
        params.id,
        newConfig
      );

      thunkAPI.dispatch(getCataloguesByAdmin());
      return data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getPublicCatalogues = createAsyncThunk(
  'catalogue/public',
  async (thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/public/catalogue/index?properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCatalogue = createAsyncThunk(
  'catalogue/create',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/catalogue/store`,
        params.catalogueInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateCatalogue = createAsyncThunk(
  'catalogue/create',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/catalogue/update`,
        params.catalogueInfo,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const searchCatalogues = createAsyncThunk(
  'catalogue/search',
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get('user'));
      let teamKey = getTeamRoleKey(user.team_roles, 'admin');
      const newConfig = {
        ...config,
        'Content-Type': 'application/json',
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/catalogue/show?id=${params.id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
