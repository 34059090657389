import "../../styles/pagination/main.css";

export default function MainPagination({ currentPage, totalPages, setCurrentPage }) {

    function handlePageChange(page) {
        if (page < 1 || page > totalPages) {
            return;
        }

        if (page === currentPage) {
            return;
        }

        setCurrentPage(page);
    }

    return (
        <div className="mainpagination__container">
            {Array.from({ length: totalPages }, (_, index) => (
                <button
                    key={index + 1}
                    className={`mainpagination__page ${currentPage === index + 1 ? 'mainpagination__page_active' : ''}`}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </button>
            ))}
        </div>
    );
}