import axios from "axios";
import Cookies from "js-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTeamRoleKey } from "../utils/getUserTeamRoleKey";

const baseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const joinWaitlist = async (params) => {
  try {
    const newConfig = {
      ...config,
      "Content-Type": "application/json",
      headers: {
        ...config.headers,
      },
    };
    const data = await axios.post(
      `${baseUrl}/api/wait-list/store`,
      params,
      newConfig
    );

    return data.data;
  } catch (err) {
    return err;
  }
};

export const getAllPublicMerchandiseAction = createAsyncThunk(
  "public/merchandise/index",
  async ({ page = 1 }, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/public/merchandise/index?properties=1&page=${page}`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveAStoreByCreator = createAsyncThunk(
  "api/store/approve",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      let teamKey = getTeamRoleKey(user.team_roles, "store");

      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };
      const data = await axios.post(
        `${baseUrl}/api/store/approve/store`,
        params.approveData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const filterAllPublicMerchandiseAction = createAsyncThunk(
  "public/merchandise/filter/index",
  async (params, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/public/merchandise/filter/index`,
        params.filterData,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAPublicMerchandiseAction = createAsyncThunk(
  "public/merchandise/show",
  async (params, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/public/merchandise/show?id=${params.id}&properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserCartAction = createAsyncThunk(
  "cart/get",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/cart/me?properties=1`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getClientSecret = createAsyncThunk(
  "payment/get",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));

      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        params: {
          id: params.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/api/payment/pay-by-stripe`,
        newConfig
      );
      return response.data.data;
    } catch (err) {
      // Extract only necessary information from Axios error object
      const error = {
        message: err.message,
        name: err.name,
        code: err.code,
        status: err.response ? err.response.status : null,
      };
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserCartAction = createAsyncThunk(
  "cart/update",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/cart/update`,
        params.updateData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateUnauthenticatedCart = createAsyncThunk(
  "cart/update",
  async (params, thunkAPI) => {
    try {
      const newConfig = {
        "Content-Type": "application/json",
      };

      const data = await axios.post(
        `${baseUrl}/api/public/cart/load`,
        params.updateData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const storeOrderWhileUnAuthenticatedAction = createAsyncThunk(
  "order/store",
  async (params, thunkAPI) => {
    console.log(params.storeData);
    try {
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/public/order/checkout`,
        params.storeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkoutUserCartAction = createAsyncThunk(
  "cart/update",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/cart/checkout`,
        params.checkoutData,
        newConfig
      );
      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const zeroDollarAction = createAsyncThunk(
  "cart/zero",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
        params: {
          id: params.id,
        },
      };
      const data = await axios.get(
        `${baseUrl}/api/payment/zero-dollar-payment`,

        newConfig
      );

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const emptyUserCartAction = createAsyncThunk(
  "cart/empty",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/cart/empty`,
        params.updateData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createPaymentAction = createAsyncThunk(
  "payment/store",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/payment/store`,
        params.storeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createOrderAction = createAsyncThunk(
  "order/store",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.post(
        `${baseUrl}/api/order/store`,
        params.storeData,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllCategoriesAction = createAsyncThunk(
  "product/category/index",
  async (params, thunkAPI) => {
    try {
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/product/category/index`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getGoogleCloudTokenAction = createAsyncThunk(
  "product/category/index",
  async (params, thunkAPI) => {
    try {
      let user = JSON.parse(Cookies.get("user"));
      let teamKey = getTeamRoleKey(user.team_roles, "store");
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          Team: teamKey,
          Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/product/category/`,
        newConfig
      );

      return data.data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getPaymentProviderKeysAction = createAsyncThunk(
  "payment/show/provider",
  async (params, thunkAPI) => {
    try {
      // let user = JSON.parse(Cookies.get('user'));
      // let teamKey = getTeamRoleKey(user.team_roles, 'store');
      const newConfig = {
        ...config,
        "Content-Type": "application/json",
        headers: {
          ...config.headers,
          // Team: teamKey,
          // Authorization: `Bearer ${user.access_token}`,
        },
      };

      const data = await axios.get(
        `${baseUrl}/api/payment/show/provider`,
        newConfig
      );

      return data.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
